import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DatadogLoggingService } from '@edx/frontend-logging';
import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import OutlineTrayPlugin from '@edx/left-sidebar-navigation-plugin';
import CourseOutlineSidebarTriggerPlugin from '@edx/course-outline-sidebar-trigger-plugin';
import UnitTitle from '@edx/unit-title-plugin';


const config = {
  ...process.env,
  loggingService: DatadogLoggingService,
  pluginSlots: {
    course_outline_sidebar_slot: {
      keepDefault: false,
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'course_outline_sidebar_slot',
            type: DIRECT_PLUGIN,
            RenderWidget: OutlineTrayPlugin,
          },
        },
      ]
    },
    course_outline_sidebar_trigger_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'course_outline_sidebar_trigger_slot',
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: (props) => {
              return (
                <CourseOutlineSidebarTriggerPlugin Link={Link} {...props} />
              );
            },
          },
        },
      ]
    },
    unit_title_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'unit_title_slot',
            type: DIRECT_PLUGIN,
            priority: 1,
            RenderWidget: (props) => {
              const dispatch = useDispatch();
              return <UnitTitle {...props} dispatch={dispatch}  />;
            },
          },
        },
      ],
    },
  }
};

export default config;
